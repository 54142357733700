import React from "react";
import { graphql } from "gatsby";
import Layout from "components/layout";
import styles from "./styles.module.css";

export const query = graphql`
  query LegalPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
      }
    }
  }
`;

const LegalTemplate = ({ data, pageContext }) => {
  const { title } = data.markdownRemark.frontmatter;

  return (
    <Layout title={title}>
      <div className={styles.legal}>
        <h1>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
      </div>
    </Layout>
  );
};

export default LegalTemplate;
